<template>
  <b-row>

    <div class="col-12">
      <b-col v-if="preview" cols="" class="text-center mt-1 mb-2 mx-auto" style="width:fit-content">
          <div class="text-center mb-1"> <p class="font-weight-bolder">Advertisement</p> </div>
          <span v-html="preview"></span>
      </b-col>
    </div>

    <b-col cols="12">
      <b-card-code>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block mr-1"
              />
              <b-button
                variant="primary"
                :to="{name:'advertiser-banner-create'}"
              >
                <span class="text-nowrap"> + Add New Banner</span>
              </b-button>
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{enabled: true, externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{enabled: true,perPage:pageLength}"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: campaign_intent -->
            <span v-if="props.column.field === 'banner_thumbnail_preview' && props.row.banner_img_url != null ">
                <img style="max-width:12.5rem;max-height:12.5rem;" :src="'https://purplepatch.online'+props.row.banner_img_url" alt="banner preview">                
            </span>

            <!-- Column: ad group Status -->
            <span v-if="props.column.field === 'banner_status_custom'">
              <b-form-checkbox
                :id="'bannerStatus_'+props.row.banner_id"
                :key="props.row.banner_id"
                :checked="props.row.banner_status==1?true:false"
                switch
                @change="statusChange($event, props.row.banner_id)"
              />
            </span>

            <!-- Column: Inventory Status -->
            <span v-if="props.column.field === 'banner_dimension' && props.row.banner_width">
                {{props.row.banner_width}}x{{props.row.banner_height}}
            </span>

            <!-- Column: banner_association -->
            <span v-if="props.column.field === 'banner_association' && props.row.campaign_name">
                {{props.row.campaign_name}} -> {{props.row.adgroup_name}}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="bannerPreview(props.row.banner_id)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    <span>Preview Top</span>
                  </b-dropdown-item>
                  
                  <b-dropdown-item
                    :to="{name:'advertiser-banners-preview', params: { id: props.row.banner_id } }"
                    target='_blank'
                  >
                    <feather-icon
                      icon="ExternalLinkIcon"
                      class="mr-50"
                    />
                    <span>Preview Link</span>
                  </b-dropdown-item>

                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','15','20']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BRow, BButton, BCol,BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Thumbnail',
          field: 'banner_thumbnail_preview',
        },
        {
          label: 'Banner Name',
          field: 'banner_name',
        },
        {
          label: 'Banner Dimension',
          field: 'banner_dimension',
        },
        {
          label: 'Campaign Association',
          field: 'banner_association',
        },
        {
          label: 'Status',
          field: 'banner_status_custom',
        },
        {
          label: 'Action',
          field: 'action',
        }
      ],
      rows: [],
      searchTerm: '',
      preview:null
    }
  },
  methods:{
    statusChange(id){
        console.log(id);
    },
    bannerPreview(id){
        const accessToken = useJwt.getToken('accessToken') 
        if(id){
          // Get edit form data
          this.$http.get('https://api.purplepatch.online/advertiser/banner/preview/'+id, { 
              headers: {
              Authorization:'Bearer ' + accessToken,
              'x-access-token': accessToken 
              } 
          })
          .then(response => {
              let data = response.data;
              this.preview=data.banner_adtag;
              console.log(data);
          })
          .catch(error => {
              console.log(error);
          })
        }
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0 : 'light-warning',
        1 : 'light-success',
        2 : 'light-danger'
      }
      return status => statusColor[status]
    },
    approvalText() {
      const approval = {
        0: 'Pending',
        1: 'Approved',
        2: 'Rejected',      
      }
      return status => approval[status]
    },
  },
  created() {
    const accessToken = useJwt.getToken('accessToken')
    this.$http.get('https://api.purplepatch.online/advertiser/banners', {
      headers: {
        Authorization:'Bearer ' + accessToken,
        'x-access-token': accessToken 
      }
    })
    .then(res => { 
      console.log('status: ', res.status);
      console.log('statusText: ', res.statusText);
      console.log(res);
      this.rows = res.data;
    })
    .catch(error => {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('data' + error.response.data);
        console.log('status' + error.response.status);
        console.log('status' + error.response.statusText);
        console.log('headers' + JSON.stringify(error.response.headers));
        console.log('Error' +  error.message);
        console.log(error.request);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    })
  },
}
</script>

<style lang="scss" >
  @import '@core/scss/vue/libs/publisher.scss';
</style>
